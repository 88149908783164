export const allSnippets = ([
    {
        key: '1',
        linkId: 'https://earth-nodes.vercel.app/',
        header: 'Earth Nodes',
        tags: 'React Three Fiber',
        Img: require('../../assets/just-earth.webp'),
        tag: ['snippet', 'r3f', 'react']
    },

    {
        key: '2',
        linkId: 'https://just-cards.vercel.app/',
        header: 'Just Cards',
        description: 'Axo cards displayed',
        tags: 'React',
        Img: require('../../assets/just-cards.jpg'),
        tag: ['snippet', 'react']
    },

    {
        key: '3',
        linkId: 'https://isauljosue.gitlab.io/isaul.garcia/room360x.html',
        header: 'Room X',
        description: 'My quarantined reality',
        tags: 'Artwork, Javascript',
        Img: require('../../assets/cc-rx.jpg'),
        tag: ['snippet', 'artwork', 'vanilla']
    },

    {
        key: '4',
        linkId: 'https://loma-snippet.vercel.app/',
        header: 'Loma',
        description: ``,
        tags: 'React Three Fiber',
        buttonlinkey: '/',
        Img: require('../../assets/loma-tmb2.png'),
        tag: ['snippet', 'r3f', 'react']
    },

    // {
    //     key: '5',
    //     linkId: 'el-hoyo',
    //     header: 'El Hoyo',
    //     description: `Documenting eveything that happens in "el hoyo"`,
    //     tags: 'Artwork',
    //     buttonlinkey: '/',
    //     Img: require('../../assets/ss-hoyo.jpg'),
    //     tag: ['snippet', 'html/css', 'neocities']
    // }
]);

export const allProjects = ([
    {
        key: '13',
        isBlog: true,
        linkId: '/someware',
        header: 'Building my place wide web',
        tags: '5/19/2022',
        Img: require('../../assets/cc-sw.jpg'),
        tag: ['blogs']
    },

    {
        key: '12',
        isBlog: true,
        linkId: '/hello-internet',
        header: 'Hello, Internet',
        tags: '2/18/2022',
        Img: require('../../assets/cc-unk.jpg'),
        tag: ['blogs']
    },

    {
        key: '10',
        linkId: '/gridy',
        header: 'Gridy',
        description: "Generate music visualizer of album artworks",
        tags: 'Spotify API, Axios',
        Img: require('../../assets/cc-gr.jpg'),
        tag: ['projects']
    },

    {
        key: '9',
        hasTag: true,
        linkId: '/digiden',
        header: 'Digiden',
        description: 'Tech news website prototype',
        tags: 'React',
        Img: require('../../assets/cc-dg.jpg'),
        tag: ['mocks']
    },

    {
        key: '6',
        linkId: '/anaquel',
        header: 'Anaquel',
        description: 'Bookmark manager and Chrome extension',
        tags: 'Redux, MongoDB',
        Img: require('../../assets/ss-an.jpg'),
        tag: ['projects']
    },

    {
        key: '11',
        linkId: '/antillias',
        header: 'Antillias',
        description: 'A demonstration of phygital places',
        tags: 'Three.js, Arduino',
        Img: require('../../assets/cc-antillias.jpg'),
        tag: ['projects']
    },

    {
        key: '5',
        hasTag: true,
        linkId: '/earth',
        header: 'Here on Earth',
        description: 'Informational website prototype',
        tags: ' Victory, Three.js',
        Img: require('../../assets/cc-ee.jpg'),
        tag: ['mocks']
    },

    {
        key: '4',
        hasTag: true,
        linkId: '/markado',
        header: 'Markado',
        description: 'Online commerce website prototype',
        tags: 'React',
        Img: require('../../assets/cc-mk.jpg'),
        tag: ['mocks']
    },

    {
        key: '8',
        hasTag: true,
        linkId: '/loma',
        header: 'Loma',
        description: 'Customizable product website prototype',
        tags: 'Three.js, React Color',
        Img: require('../../assets/cc-lm.jpg'),
        tag: ['mocks']
    },

    {
        key: '7',
        linkId: '/mods',
        header: 'MODs',
        description: 'Modular urban intervention for the future of cities',
        tags: 'Smart Cities',
        Img: require('../../assets/cc-mds.jpg'),
        tag: ['projects']
    },

    {
        key: '2',
        linkId: '/treehouse',
        header: ':treehouse',
        description: 'Self-sustainable network of tree houses',
        tags: 'Architecture, IoT',
        Img: require('../../assets/cc-th.jpg'),
        tag: ['projects']
    },

    {
        key: '1',
        linkId: '/roca',
        header: 'Roca',
        description: 'Environmentally conscious elementary school',
        tags: 'Parametric Design',
        Img: require('../../assets/cc-rca.jpg'),
        tag: ['projects']
    },
]);