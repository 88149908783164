export const myQuestions = ([

    {
        helperId: 'pages',
        question: 'Roughly how many pages?',
        answerPlaceHolder: 'Enter a number',
        typeOfAnswer: 'Number Blank'
    },
    {
        helperId: 'pages',
        question: 'How complex is the design?',
        answerPlaceHolder: 'Select',
        typeOfAnswer: 'Number Blank',
        isSelector: true,
        optionItem: [ 
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10
        ]
    },
    {
        helperId: 'pages',
        question: 'How many rounds of revision?',
        answerPlaceHolder: 'Max 3',
        typeOfAnswer: 'Number Blank',
        isSelector: true,
        optionItem: [ 
            1, 2, 3
        ]
    },
    {
        helperId: 'pages',
        question: "What's your hourly rate?",
        answerPlaceHolder: 'Enter in $',
        typeOfAnswer: 'Number Blank'
    },
    {
        helperId: 'pages',
        question: "Number of pages to be migrated?",
        answerPlaceHolder: 'Enter a number',
        typeOfAnswer: 'Number Blank',
    },
    {
        helperId: 'pages',
        question: "What kind of website?",
        answerPlaceHolder: 'Select one',
        typeOfAnswer: 'Number Blank',
        isSelector: true,
        optionItem: [ 
            'Generic',
            'e-Commerce',
            'Blog',
            'Portfolio',
            'Marketing/Brochure',
        ]
    },
    {
        helperId: 'pages',
        question: "Will you have complex frontend animations?",
        answerPlaceHolder: 'Choose',
        typeOfAnswer: 'Number Blank',
        isSelector: true,
        optionItem: [ 
            'None',
            'Minor',
            'Intermediate',
            'Advanced',
        ]
    },
    {
        helperId: 'pages',
        question: "How many copywriting pages?",
        answerPlaceHolder: 'Enter a number',
        typeOfAnswer: 'Number Blank'
    },
    {
        helperId: 'pages',
        question: "Which describes your client?",
        answerPlaceHolder: 'Choose',
        typeOfAnswer: 'Number Blank',
        isSelector: true,
        optionItem: [ 
            'Fortune500',
            'Small Business',
            'Funded Startup',
            'Non-Funded Startup',
            'Non-Profit',
            'Service Based',
            'Healthcare',
            'General',
            'Corporate'
        ]
    },
    {
        helperId: 'pages',
        question: "How budget conscious does your client seem?",
        answerPlaceHolder: 'Choose',
        typeOfAnswer: 'Number Blank',
        isSelector: true,
        optionItem: [ 
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10
        ]
    },
    {
        helperId: 'pages',
        question: "How much revenue could this project generate for your client?",
        answerPlaceHolder: 'Choose',
        typeOfAnswer: 'Number Blank',
        isSelector: true,
        optionItem: [ 
            "Some",
            "A lot",
            "Not Sure"
        ]
    },
    {
        helperId: 'pages',
        question: "Will this project save your client time and thereby money?",
        answerPlaceHolder: 'Choose',
        typeOfAnswer: 'Number Blank',
        isSelector: true,
        optionItem: [ 
            "Yes",
            "No",
            "Not Sure"
        ]
    },
    {
        helperId: 'pages',
        question: "How much you want this job?",
        answerPlaceHolder: '1 - 10',
        typeOfAnswer: 'Number Blank',
        isSelector: true,
        optionItem: [ 
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10
        ]
    },
    {
        helperId: 'pages',
        question: "How busy are you right now?",
        answerPlaceHolder: 'Choose',
        typeOfAnswer: 'Number Blank',
        isSelector: true,
        optionItem: [ 
            "Very Busy",
            "Busy",
            "Normal",
            "Not Very Busy",
            "Free"
        ]
    },
    {
        helperId: 'pages',
        question: "Opportunity for future revenue?",
        answerPlaceHolder: 'Choose',
        typeOfAnswer: 'Number Blank',
        isSelector: true,
        optionItem: [ 
            "Some",
            "A lot",
            "Not sure"
        ]
    }
]);
