export const blogHello = ([
    {
        helperId: 'hello-internet',
        title: 'Hello, Internet',
        articleText: `After plenty of disorganized .txt files, I've decided to take my toughts into a more efficient format. Perhaps excersice my novice writing skills. More so, I'd like to make this my main place within the internet. Filled with my ideas, my art, and any other content that inspires me. It will also serve as a place to document my projects, for them to exist in an open place for consumption, utilization and collaboration.`,
        isFrame: false,
        isImage: false
    },

    {
        articleText: `Some of the topics might include: the web, coding, data visualizations, digital art, architecture, music, puerto rico, pluriverse, the phygital, and some other made up terms like, place wide web, someware, mutual interfaces, etc.`,
        isFrame: false,
        isImage: false
    },

    {
        articleText: ``,
        isFrame: false,
        isImage: false,
        Img: ''
    }
]);

export const blogSomeware = ([
    {
        isTitle: true,
        articleTitle: `Building my place wide web`,
        subTitle: `Revisiting the work I did for "Someware".`,
        datePosted: `05/04/2022`
    },
    
    {
        articleText: `For this first blog I'm revisiting part of the project I did for my thesis, "Someware: Exploring Place Computing". I wanted to get a temp check on where the idea is, and how some of my beliefs and ideas around the subject have evolved over these past few years. The project is currently in the thesis documentation website, but I wanted to migrate some of it here, and add a little bit more commentary, before taking the topic any further. As well as having a frame of reference of where I was, for the future projects I post on here.`,
        isText: true
    },

    {
        isSeparator: true
    },

    {
        articleText: `Someware explores the concept of place computing. Using placemaking as a tool to interpret and augment our context. Its objective is to acknowledge a place as a computing entity. Primarily to make the distinction between the internet as a world wide computer and the phone as a compact personal computer, this mutual computer stands somewhere in between. Place Computing is something that grows from the ground and not just the cloud. It's a hybrid between the presence of what's there physically and virtually.`,
        isText: true
    },

    {
        articleText: ``,
        isImage: true,
        Img: require('../assets/sw-scan-ball.webp')
    },
    
    {
        articleText: `From the Ground`,
        isText: true
    },

    {
        articleText: `I scanned and took 360 images of my room to use as a base for virtual content. I used the 3D scanning app 'Display.Land' to create a 3D textured model of my room. I also constructed a 360 Image with photogrammetry tools.`,
        isText: true
    },

    {
        articleText: `Inversely, I equipped my physical space with some self-made sensors to tether the content and add environmental interactions. I then did some prototyping and testing with the self-made sensors.`,
        isText: true
    },

    {
        articleText: ``,
        isImage: true,
        Img: require('../assets/sw-firstsens.webp')
    },

    {
        articleText: `Embedding them into the environment.`,
        isText: true
    },

    {
        articleText: ``,
        isImage: true,
        Img: require('../assets/sw-sens.webp')
    },

    {
        articleText: `In addition to that, I tried visualizing my room as my computer. What if I measured my furniture like I measured storage in my pc? What if my address is written like a link path? How would I navigate my furniture's metadata? I then extended the representation to an Augmented Reality 3D interface.`,
        isText: true
    },

    {
        articleText: ``,
        isImage: true,
        Img: require('../assets/sw-pwwui.webp')
    },

    {
        articleText: ``,
        isImage: true,
        Img: require('../assets/sw_big.webp')
    },

    {
        articleText: `Testing the structure`,
        isText: true
    },

    {
        articleText: `During testing I began prototyping how tethered interaction could work. Adding the sensors to the door, window and another one measuring the amount of light in the room.`,
        isText: true
    },

    {
        articleText: ``,
        isImage: true,
        Img: require('../assets/swi1-opt.gif')
    },

    {
        articleText: ``,
        isImage: true,
        Img: require('../assets/swi2-opt.gif')
    },

    {
        articleText: `Use case exploration: What does it mean to play a video game on my room computer?`,
        isText: true
    },

    {
        articleText: ``,
        isImage: true,
        Img: require('../assets/sw-guyfloor.webp')
    },

    {
        articleText: `My floor becomes the map and the environment my controller. With this I created the first prototype to test the gameplay. Due to my limitations, I used Unity to create the virtual experience. Then prototyped the interactions and web native possibility, separately. It is important for the values of the project that this was made thinking of the web as the deployment platform for various reasons. Some of which where accessibility and the open-source nature of the project. Web graphics are also progressing fast which leads part of my enthusiasm for this project.`,
        isText: true
    },

    {
        articleText: ``,
        isImage: true,
        Img: require('../assets/swgameplay-opt.webp')
    },

    {
        articleText: `Content into Context`,
        isText: true
    },

    {
        articleText: `Much of my exploration is led by the idea that we would partly go back to tethering content into context. This means using web technology as a means to create degrees of locality for content. This would allow for a place to take ownership of the digital presence inside it, or a layer of it. Hopefully this augments the immediate environment in a way that is not invasive to the identity of places. As I'm experimenting with alternate realities living in my room, it's clear that the hybrid presence in this case is my identity. What I do inside my room both physically and virtually.`,
        isText: true
    },

    {
        articleText: ``,
        isImage: true,
        Img: require('../assets/ss-rx.jpg')
    },
    
    {
        isDesc: true,
        descText: `Room X artwork, "My quarantined reality: Merging my digital and physical life"`
    },

    {
        articleText: `Multiplicity of Interactions`,
        isText: true
    },

    {
        articleText: `In terms of how it is experienced, an important factor is that it's not segregating. The project is meant to have the world building process include several mediums of interactions. This scale of computing is different. The internet deals with global accessibility of information, while the phone is an individualized interaction experience. This is an experience that exists somewhere in between. Including phones, head mounted displays, smart things, any things, plants, animals, etc. in a type of mutual interface. The information is still somewhat publicly accessible, it is mostly about its direct relashionship with the context in terms of the experience.`,
        isText: true
    },

    {
        articleText: ``,
        isImage: true,
        Img: require('../assets/sw-devic.webp')
    },

    {
        articleText: `Best course of action?`,
        isText: true
    },

    {
        articleText: `Much of what the technology is promising right now is presented by connecting all of your 'Internet of Things' devices to the back-ends of the big corporations selling these things. For Mixed Realities, AR and VR, is looking more and more like it could be implemented using cloud computing platforms. Both of these paths promise performance but at the expense of surveillance and loss of control. Implementing a distributed back-end of things, and perhaps other sources of distributed computing, is the desired course of development.`,
        isText: true
    },

    {
        articleText: ``,
        isImage: true,
        Img: require('../assets/sw-ballnet-l.webp')
    },

    {
        isSeparator: true
    },
    
    {
        articleText: `In Hindsight`,
        isText: true
    },

    {
        articleText: `These are very raw ideas of the vision I had for this project. What's written here is only the last presentation which was designed around my bedroom at the time. The thesis process took much longer and involved extended writing, references and other interventions. Some of the interventions, including the last project, were meant to be done in public but couldn't happen due to the pandemic.`,
        isText: true
    },

    {
        articleText: `Generally the topic has been talked about various times before with different terminology. My intention with this project was to counter the argument of computing happening around us without our awareness or control of it, as Ubiquitous Computing presents it. We all know the surveillance and security risks that can come with this type emergent technology evolving in the hands of corporations and authoritarian leaders. Part of what I was doing was to give this scale of computing the interface it deserves, and speculate on a structure for it to become acceptable.`,
        isText: true
    },

    {
        articleText: `This project was created before the big NFT boom and Facebook's rebranding to Meta. They have a direct impact on my ideas and my approach towards the topic. Much of the conversation became mainstream, especially with the resurgence of the term "Metaverse". This term in particular was already becoming a buzzword before Facebook appropriated it. It was used by the Web3 community to refer to a direction of certain trends and values they are aspiring to. Most of these directly correlated with Someware; digital assets, cross platform, decentralization, community driven, etc.. Which is part of the reason why I was drawn to Web3 initially. Despite seeing a lot of overlap from the Metaverse and Web3, I still think the physicality of Someware mostly remains out of it; e.g. Internet of Things, Geolocation, Placemaking, etc..`,
        isText: true
    },

    {
        articleText: `All of this has reshaped my beliefs around Someware. The project as it currently is, involves a lot of different topics. It is still a mystery for me whether this project is just a means of learning and exploration, or whether it has the potential to become something else. Regardless, it's something I will keep developing for now.`,
        isText: true
    }

]);
