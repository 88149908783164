/* color scheme */

export const lightest = '#f1f1f1';
export const lighter = '#dfdfdf';
export const light = '#bcbcbc';
export const grey = '#888888';
export const dark = '#262626';
export const darker = ' #161616';
export const darkest = '#000000';

export const primary = '#8d9fe3';
export const secondary = '#48ffaa';
export const terciary = '#ffee00';

export const accent = '#44ccee';

export const warning = '#fe5588';
export const approve = '#48ff88';